.greeting_container {
  margin-top: 5vh;
  padding-top: 5%;
  padding-bottom: 5%;
  /* padding-left: 10%; */
}

.hello {
  font-size: 60px;
  /* font-size: 300%; */
  font-weight: 300;
  color: #46bbfc;
  margin: 0;
  padding: none;
  animation: fadein 1s;
  /* animation-delay: 2s; */
}

.name {
  /* color: white; */
  font-size: 100px;
  /* font-size: 500%; */
  margin: 0;
  padding: none;
  color: white;
  animation: fadein 1.5s;
  /* animation-delay: 2s; */
}

.subtitle {
  /* font-size: 400%; */
  font-size: 80px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  color: #a3dafa;
  margin: 0;
  animation: fadein 2s;
  /* animation-delay: 2s; */
}

.sidelinks_container {
  position: fixed;
  z-index: 2;
  right: 100px;
  bottom: 150px;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translate(0px, 50px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

/* >1600px screen width ——————————————————————————————————————————————————————————*/
@media (min-width: 1600px) {
  .greeting_container {
    margin-top: 150px;
  }

  .hello {
    font-size: 50px;
  }
  .name {
    font-size: 100px;
  }
  .subtitle {
    font-size: 85px;
  }
}

/* <1400px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 1400px) {
  .greeting_container {
    margin-top: 10vh;
  }
}

/* <1300px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 1300px) {
  .greeting_container {
    margin-top: 150px;
  }

  .hello {
    font-size: 50px;
  }

  .name {
    font-size: 80px;
  }

  .subtitle {
    font-size: 65px;
  }
}

/* <1200px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 1200px) {
}

/* <1050px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 1200px) {
  .sidelinks_container {
    right: 50px;
  }

  .hello {
    font-size: 60px;
  }

  .name {
    font-size: 75px;
  }

  .subtitle {
    font-size: 60px;
  }
}

@media (max-width: 1050px) and (max-height: 2000px) {
  .greeting_container {
    margin-top: 20vh;
  }
}

@media (max-width: 1050px) and (max-height: 1500px) {
  .greeting_container {
    margin-top: 20vh;
  }
}

@media (max-width: 1050px) and (max-height: 900px) {
  .greeting_container {
    margin-top: 5vh;
  }
}

/* <1000px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 1000px) {
  .hello {
    font-size: 40px;
  }

  .name {
    font-size: 62px;
  }

  .subtitle {
    font-size: 50px;
  }

  .sidelinks_container {
    right: 30px;
  }
}

/* <900px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 900px) {
  .sidelinks_container {
    /* margin-left: 83vw; */
  }
}

/* <850px screen width ——————————————————————————————————————————————————————————*/
/* Top navigation activated */
@media (max-width: 850px) {
  .greeting_container {
    margin-top: 150px;
  }

  .hello {
    font-size: 40px;
    margin-bottom: 20px;
  }

  .name {
    font-size: 55px;
    margin-bottom: 20px;
  }

  .last_name {
    /* display: none; */
  }

  .subtitle {
    font-size: 45px;
    margin-bottom: 20px;
  }

  .subtext_container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 5% auto 0 0;
    animation: fadein 3s;
  }

  .subtext {
    font-size: 35px;
    color: #a3dafa;
    font-weight: 200;
  }
  

  .subtext_link {
    font-size: 30px;
    font-weight: 600;
    color: #053742;
    width: 450px;
    margin: 5% 0;
    padding: 7%;
    background-color: #a3dafa;
    border: 1px solid white;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
  }

  .subtext_link:hover {
    background-color: #d1edfd;
    color: #053742;
    border-color: white;
    text-decoration: none;
  }
  .subtext_link:active {
    background-color: #053742;
    color: white;
    text-decoration: none;
  }
}

/* <750px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 750px) {
  .hello {
    font-size: 180%;
  }

  .name {
    font-size: 300%;
  }

  .subtitle {
    font-size: 250%;
  }

  .subtext_link {
    width: 400px;
  }
}

/* <700px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 700px) {
  .hello {
    font-size: 180%;
  }

  .name {
    font-size: 42px;
  }

  .subtitle {
    font-size: 35px;
  }
}

/* <650px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 650px) {
  .hello {
    font-size: 160%;
  }

  .name {
    font-size: 38px;
  }

  .subtitle {
    font-size: 200%;
  }
  .subtext {
    font-size: 30px;
  }
}

/* <550px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 550px) {
  .hello {
    font-size: 25px;
  }

  .name {
    font-size: 34px;
  }

  .subtitle {
    font-size: 28px;
  }

  .subtext {
    font-size: 25px;
  }

  .subtext_link {
    width: 350px;
  }
}

@media (max-width: 550px) and (max-height: 900px) {
  .greeting_container {
    margin-top: 30vh;
  }
}

/* <480px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 480px) {
  .hello {
    font-size: 25px;
  }

  .name {
    font-size: 30px;
  }

  .subtitle {
    font-size: 25px;
  }

  .subtext_container {
    width: 80%;
  }

  .subtext {
    font-size: 21px;
  }

  .subtext_link {
    width: 300px;
  }
}

/* <430px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 430px) {
  .hello {
    font-size: 110%;
  }

  .name {
    font-size: 28px;
  }

  .subtitle {
    font-size: 140%;
  }

  .subtext_link {
    font-size: 25px;
  }
}

/* <400px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 400px) {
  .hello {
    font-size: 110%;
  }

  .name {
    font-size: 25px;
  }

  .subtitle {
    font-size: 130%;
  }

  .subtext_container {
    width: 100%;
  }

  .subtext_link {
    font-size: 20px;
    width: 200px;
  }
}

/* <380px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 380px) {
  .hello {
    font-size: 100%;
  }

  .name {
    font-size: 21px;
  }

  .subtitle {
    font-size: 18px;
  }

  .subtext {
    font-size: 100%;
  }
}


@media (max-height: 700px) {
  .greeting_container {
    margin-top: 80px;
  }
}