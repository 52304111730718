.container {
  position: fixed;
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #e8eff171;
  backdrop-filter: blur(1px);
  height: 10vh;
  margin: none;
  top: 0;
  transition-timing-function: ease-in;
  transition: 1s;
  z-index: 2;
}

.content_container {
  display: flex;
  flex-direction: row;
  width: 90vw;
  margin-left: 5vw;
}

.logo_container {
  display: flex;
  left: 0;
  margin: auto auto auto 0;
}

.container img {
  display: flex;
  float: right;
  width: 40px;
}

.container img:hover {
  cursor: pointer;
}

/* Styling side navigation */
.expnav_container {
  position: fixed;
  background-color: #053742;
  display: flex;
  flex-direction: column;
  padding: 0 0 100vh 0;
  width: 40%;
  margin: 0 0 0 60%;
  animation: slideleft 200ms;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

.expnav_close {
  color: white;
  width: 100%;
  padding: 2%;
  margin: 5% auto 0 5%;
  background-color: transparent;
}

.expnav_close:hover {
  cursor: pointer;
}

.expnav_content {
  display: flex;
  flex-direction: column;
  margin: 30% 0 0 0;
  background-color: transparent;
}

.expnav_link {
  font-size: 200%;
  color: #e8eff1;
  margin: 10% auto;
}

.expnav_link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.resume {
  color: #46bbfc;
}

@keyframes slideleft {
  from {
    transform: translate(800px, 0px);
  }
  to {
    transform: translate(0px, 0px);
  }
}

/* <600px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 600px) {
  .expnav_link {
    font-size: 150%;
  }
}

/* <400px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 400px) {

  img.expnav_close {
    width: 20px;
  }
}
