@media (max-height: 2000px) {
  .full_container {
    height: 300vh;
  }
}

@media (max-height: 1200px) {
  .full_container {
    height: 300vh;
  }
}

@media (max-height: 1500px) and (max-width: 3400px) {
  .full_container {
    height: 400vh;
  }
}

@media (max-height: 1500px) and (max-width: 3000px) {
  .full_container {
    height: 320vh;
  }
}

@media (max-height: 1500px) and (max-width: 2500px) {
  .full_container {
    height: 300vh;
  }
}

@media (max-height: 1400px) and (max-width: 3400px) {
  .full_container {
    height: 400vh;
  }
}

@media (max-height: 1400px) and (max-width: 3000px) {
  .full_container {
    height: 350vh;
  }
}

@media (max-height: 1400px) and (max-width: 2500px) {
  .full_container {
    height: 300vh;
  }
}

@media (max-height: 1300px) and (max-width: 3400px) {
  .full_container {
    height: 500vh;
  }
}

@media (max-height: 1300px) and (max-width: 3000px) {
  .full_container {
    height: 400vh;
  }
}

@media (max-height: 1300px) and (max-width: 2500px) {
  .full_container {
    height: 350vh;
  }
}

@media (max-height: 1200px) and (max-width: 3400px) {
  .full_container {
    height: 500vh;
  }
}

@media (max-height: 1200px) and (max-width: 3000px) {
  .full_container {
    height: 450vh;
  }
}

@media (max-height: 1200px) and (max-width: 2500px) {
  .full_container {
    height: 400vh;
  }
}

/* Stubborn iPad Pro */
@media (max-height: 1400px) and (max-width: 1200px) {
  .full_container {
    height: 250vh;
  }
}

@media (max-height: 1000px) {
  .full_container {
    height: 230vh;
  }
}

/* For wider screens and laptops */
@media (max-height: 1000px) and (max-width: 3000px) {
  .full_container {
    height: 400vh;
  }
}

@media (max-height: 1000px) and (max-width: 2000px) {
  .full_container {
    height: 350vh;
  }
}

@media (max-height: 1000px) and (max-width: 1500px) {
  .full_container {
    height: 300vh;
  }
}

@media (max-height: 950px) and (max-width: 2000px) {
  .full_container {
    height: 380vh;
  }
}

@media (max-height: 950px) and (max-width: 1500px) {
  .full_container {
    height: 320vh;
  }
}

@media (max-height: 900px) and (max-width: 1500px) {
  .full_container {
    height: 330vh;
  }
}

@media (max-height: 850px) and (max-width: 1500px) {
  .full_container {
    height: 340vh;
  }
}

@media (max-height: 810px) and (max-width: 1500px) {
  .full_container {
    height: 350vh;
  }
}

/* Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    @media (max-height: 850px) and (max-width: 600px) {
      .full_container {
        height: 350vh;
      }
    }
  }
}

@media (max-height: 850px) and (max-width: 2500px) {
  .full_container {
    height: 480vh;
  }
}

@media (max-height: 850px) and (max-width: 2000px) {
  .full_container {
    height: 450vh;
  }
}

@media (max-height: 850px) and (max-width: 1800px) {
  .full_container {
    height: 400vh;
  }
}

@media (max-height: 800px) and (max-width: 1500px) {
  .full_container {
    height: 380vh;
  }
}

@media (max-height: 800px) and (max-width: 1200px) {
  .full_container {
    height: 400vh;
  }
}

@media (max-height: 750px) and (max-width: 1500px) {
  .full_container {
    height: 400vh;
  }
}

@media (max-height: 700px) and (max-width: 1500px) {
  .full_container {
    height: 420vh;
  }
}

/* Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    @media (max-height: 700px) and (max-width: 1500px) {
      .full_container {
        height: 450vh;
      }
    }
  }
}

@media (max-height: 650px) and (max-width: 1500px) {
  .full_container {
    height: 500vh;
  }
}

@media (max-height: 550px) and (max-width: 1500px) {
  .full_container {
    height: 550vh;
  }
}

@media (max-height: 500px) and (max-width: 1500px) {
  .full_container {
    height: 600vh;
  }
}

/* For thinner screens */
@media (max-height: 1000px) and (max-width: 900px) {
  .full_container {
    height: 280vh;
  }
}

@media (max-height: 900px) and (max-width: 800px) {
  .full_container {
    height: 400vh;
  }
}

@media (max-height: 900px) and (max-width: 600px) {
  .full_container {
    height: 300vh;
  }
}
