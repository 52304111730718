.fade-in-section {
  opacity: 0;
  transform: translateY(5vh);
  visibility: hidden;
  transition: opacity 1s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-section.is-visible {
  width: 100%;
}

.from_left {
  opacity: 0;
  transform: translateX(-10vw);
  visibility: hidden;
  transition: opacity 1s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}

.from_left.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-section-img {
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  transform: translateY(5vh);
  visibility: visible;
  transition: opacity 1s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible-img {
  transform: none;
  visibility: visible;
}
