.form_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  margin: 200px auto 0 0;
}

.label_input_container {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 1% auto;
}

label {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 25px;
}

.input_field,
.email_field,
.message_field {
  padding: 2% 1%;
  border: 0;
  background-color: white;
  border-bottom: 1px solid #053742;
}

.input_field:focus,
.email_field:focus,
.message_field:focus {
  background-color: white;
}

.input_field {
  width: 80%;
}

.message_field {
  height: 150px;
  resize: vertical;
  width: 100%;
}

.error {
  font-size: 23px;
  color: rgb(226, 64, 64);
}

.submit_button {
  font-size: 30px;
  width: 400px;
  color: white;
  margin: 10% auto auto 20%;
  padding: 20px 30px;
  border: 1px solid #053742;
  border-radius: 5px;
  background-color: #46bbfc;
  /* box-shadow: 0px 1px 2px 1px #053742; */
}

.submit_button:hover {
  cursor: pointer;
  background-color: #46bcfcc7;
}

.submit_button:active {
  background-color: white;
  color: #053742;
  border-color: #053742;
}

.thanks_container {
  height: 100%;
  width: 100%;
  text-align: center;
}

.thanks_container h1 {
  margin: 30% auto 0 auto;
  color: white;
}

.thanks_container h3 {
  margin: 2% auto;
  color: white;
}

/* (<1400px)———————————————————————————— */
@media (max-width: 1400px) {
  label {
    font-size: 21px
  }
  .error {
    font-size: 21px;
  }
}

/* (<1100px)———————————————————————————— */
@media (max-width: 1100px) {

  .form_container {
    margin: 300px auto auto auto;
  }

  .label_input_container {
    width: 80%;
  }

  label {
    font-size: 21px
  }

  .submit_button {
    width: 300px;
    margin-left: 10%;
  }
}

/* (<850px)———————————————————————————— */
@media (max-width: 850px) {

  .form_container {
    margin: 100px auto;
  }

  .label_input_container {
    width: 100%;
  }
  
  .input_field {
    width: 100%;
  }

  label {
    font-size: 21px
  }

  .submit_button {
    width: 100%;
    margin-left: 0;
  }
}