.full_container {
  background-color: transparent;
  padding: 100px 0;
  height: 100%;
  align-items: center;
}

.year {
  font-size: 400%;
  font-weight: 800;
  color: #a3dafa;
}

.about_img {
  width: 100%;
  margin: 2%;
  border: 1px solid #053742;
  float: right;
}

.subcontainer {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  margin: auto;
  margin: 100px auto;
  /* width: 80%; */
  /* margin: 10% 5%; */
}

.subcontainer_text {
  width: 100%;
}

.subcontainer_header {
  color: #a3dafa;
  font-size: 70px;
  font-weight: 300;
  margin: 0;
}

.subcontainer_subtext {
  margin: 2% 0 2% 0;
  color: white;
  font-size: 32px;
  font-weight: 300;
}

.subcontainer_img {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.about_img {
  margin: auto;
}

/* 
*  DISCLAIMER, I give full credit for the underline animations in the following lines to 
*  a css-irl article found at (https://css-irl.info/animating-underlines/)
*/

.subcontainer_text a {
  color: inherit;
  text-decoration: none;
  background: linear-gradient(to right, white, white),
    linear-gradient(to right, #a3dafa, #a3dafa, white);
  background-size: 100% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
  transition: color 200ms;
}

.subcontainer a:hover,
.subcontainer a:focus {
  background-size: 0 0.1em, 100% 0.1em;
  color: #a3dafa;
}

.transition_subtext {
  color: white;
  margin: auto;
  font-size: 40px;
  font-weight: 300;
  text-align: center;
}

.transition_cta {
  color: #a3dafa;
  margin: 0;
  margin-top: 100px;
  font-size: 35px;
  font-weight: 300;
  text-align: center;
}

/* (<1700px)——————————————————————————— */
@media (max-width: 1700px) {
  .subcontainer {
  }

  .subcontainer_header {
    font-size: 45px;
  }

  .subcontainer_subtext {
    font-size: 24px;
  }
}
/* (<1500px)——————————————————————————— */
@media (max-width: 1700px) {
  .transition_subtext {
    font-size: 28px;
  }
  .transition_cta {
    margin-top: 80px;
    font-size: 28px;
  }
}

/* (<1400px)——————————————————————————— */
@media (max-width: 1400px) {
  .subcontainer {}

  .subcontainer_header {
    font-size: 41px;
  }

  .subcontainer_subtext {
    font-size: 21px;
  }
}

/* (<1200px)——————————————————————————— */
@media (max-width: 1200px) {
  .transition_subtext {
    font-size: 21px;
  }

  .transition_cta {
    margin-top: 50px;
    font-size: 21px;
  }

  .subcontainer_header {
    font-size: 35px;
  }

  .subcontainer_subtext {
    font-size: 18px;
  }
}

/* (<992px)——————————————————————————— */
@media (max-width: 992px) {
  .subcontainer {
    flex-direction: column-reverse;
  }

  .subcontainer:nth-of-type(3){ 
    flex-direction: column;
  }

  .subcontainer_text {
    margin: auto;
  }
  .subcontainer_img {
    margin: auto;
    margin-bottom: 50px;
  }

  .subcontainer_header {
    font-size: 35px;
  }

  .subcontainer_subtext {
    font-size: 21px;
  }
}


/* (<850px)——————————————————————————— */
@media (max-width: 800px) {
  .subcontainer {
    flex-direction: column-reverse;
  }

  .subcontainer_img {
    /* width: 60%; */
    margin: 2% auto;
    margin-bottom: 30px;
  }
  .subcontainer_subtext {
    margin: 20px 0;
  }
}

@media (max-width: 400px) {
  .subcontainer {
    flex-direction: column-reverse;
  }

  .subcontainer_img {
    width: 90%;
    margin: 2% auto;
  }

  .subcontainer_header {
    font-size: 200%;
  }

  .subcontainer_subtext {
    font-size: 100%;
  }
}
