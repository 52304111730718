.container_anchor {
  display: flex;
  color: inherit;
  text-decoration: none;
}

.navlink_container {
  display: flex;
  flex-direction: column;
  width: 390px;
  height: auto;
  /* flex-grow: 1; */
  justify-content: flex-start;
  background-color: #edf8fe;
  border: 1px solid black;
  border-radius: 10px;
  animation: slidein 2s;
  animation-timing-function: ease-out;
  text-align: center;
  margin-right: 50px;
}

.navlink_container:hover {
  transform: translate(0px, -5px);
}

.navlink_header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 15px auto 25px auto;
  padding: none;
}

.navlink_header img {
  width: 30px;
  margin-right: 25px;
}
img[alt="Minimalist face logo"] {
  width: 35px;
}

.navlink_header h2 {
  font-size: 45px;
  font-weight: 300;
  letter-spacing: 2px;
  margin: 2% 0;
}

.title {
  color: #053742;
  stroke: black;
  stroke-width: 1px;
}

.subtext {
  font-size: 30px;
  font-weight: 300;
  color: #053742;
  margin: 0 auto 15% auto;
  padding: 0 30px;
}

.nav_button {
  margin: 5% auto;
}

@keyframes slidein {
  from {
    opacity: 0;
    transform: translate(0px, 400px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

/* Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .navlink_header img,
    .navlink_header h2 {
      height: 100%;
      margin: auto 0;
    }

    .navlink_header img {
      margin-right: 5%;
    }
  }
}

/* <1600px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 1600px) {
  .navlink_container {
    width: 350px;
  }
}

/* <1450px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 1450px) {
  .navlink_container {
    width: 300px;
  }

  .navlink_header h2 {
    font-size: 40px;
  }

  .subtext {
    font-size: 28px;
    padding: 0 20px;
  }

  .navlink_header img {
    width: 25px;
    margin-right: 25px;
  }

  img[alt="Minimalist face logo"] {
    width: 30px;
  }

}

/* <1300px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 1300px) {
  .navlink_container {
    width: 260px;
  }

  .navlink_header {
    margin-bottom: 15px;
  }

  .navlink_header h2 {
    font-size: 32px;
  }

  .subtext {
    font-size: 24px;
    padding: 0 15px;
  }

  .navlink_header img {
    width: 20px;
    margin-right: 20px;
  }

  img[alt="Minimalist face logo"] {
    width: 25px;
  }

}

/* <1200px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 1200px) {
  .navlink_container {
    width: 215px;
    height: auto;
    margin-right: 20px;
  }

  .navlink_header h2 {
    font-size: 25px;
  }

  .subtext {
    font-size: 20px;
    padding: 0 10px;
  }

  .navlink_header img {
    width: 20px;
    margin-right: 20px;
  }

  img[alt="Minimalist face logo"] {
    width: 25px;
  }

}

@media (max-width: 1040px) and (max-height: 2000px) {
  .navlink_container {
    /* height: 10vh; */
  }

  .subtext {
    /* margin: 5% auto auto auto; */
  }
}

/* <850px screen width ——————————————————————————————————————————————————————————*/
@media (max-width: 850px) {
  .navlink_header h2 {
    font-size: 150%;
  }

  .subtext {
    font-size: 80%;
  }
}

/* <700px screen width ——————————————————————————————————————————————————————————*/
/* Navigation goes vertical */
@media (max-width: 700px) {
  .navlink_container {
    height: 15vh;
    margin-bottom: 5%;
    padding: 0 5%;
  }

  .navlink_header {
    margin: 2% auto 1% auto;
  }

  .navlink_header h2 {
    font-size: 100%;
  }

  .subtext {
    font-size: 80%;
  }
}

@media (max-width: 700px) and (max-height: 500px) {
  .navlink_container {
    height: 15vh;
    padding: 2%;
  }
}
