.header_container {
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.section_name {
  font-size: 30px;
  margin: 0;
  font-weight: 300;
  color: #a3dafa;
}

.line {
  transform: translate(0px, 10px);
  stroke: #a3dafa;
  stroke-width: 1%;
}

.section_name_white {
  font-size: 30px;
  margin: 0;
  font-weight: 300;
  color: white;
}

.line_white {
  transform: translate(0px, 10px);
  stroke: white;
  stroke-width: 1%;
}

@media (max-width: 850px) {
  .header_container {
    /* margin-top: 5vh; */
  }
}
