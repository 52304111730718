.full_container {
  background-color: transparent;
  padding: 1vh 1vw;
  height: 80%;
}

.projects_container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin-top: 10%;
  margin-left: 5%;
  width: 80%;
  height: 100%;
}

@media (max-width: 850px) {
  .full_container {
    height: 90%;
  }
  .projects_container {
    width: 90%;
    margin: 10% 5%;
  }
}
