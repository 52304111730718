.contact_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.contact_left {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  background-color: #00000047;
  padding: 150px 0 300px 0;
}

.contact_text {
  width: 80%;
  margin: 2% auto;
}

.header {
  font-size: 70px;
  color: white;
}

.text {
  margin: 10% auto;
  line-height: 150%;
  font-weight: 300;
  color: white;
  font-size: 35px;
}

.text b {
  text-decoration: underline;
}

.contact_right {
  width: 60%;
  height: 100%;
  padding: 2% 0 0 0;
}

/* (<1400px)———————————————————————————— */
@media (max-width: 1400px) {
  .header {
    font-size: 60px;
  }
  .text {
    font-size: 28px;
  }
}

/* @media (max-width: 800px) {
  .contact_container {
    width: 100%;
  }
} */

/* (<850px)———————————————————————————— */
@media (max-width: 850px) {
  .contact_container {
    width: 100%;
    flex-direction: column;
  }

  .contact_left {
    width: 100%;
    height: 100%;
  }

  .contact_right {
    width: 100%;
    padding: 5%;
  }
}

/* (<850px)———————————————————————————— */
@media (max-width: 850px) {
  .contact_left {
    padding: 150px 0 200px 0;
  }
}