/* 
* This version of the site is responsive to 325x400 pixels.
*
*/

.full_container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: #053742;
  z-index: 1;
  overflow: hidden;

  /* Chrome 10-25, Safari 5.1-6 */
  /* background: -webkit-linear-gradient(to top, #2f80ed, #56ccf2); */

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* background: linear-gradient(to top, #2f80ed, #56ccf2); */
}
