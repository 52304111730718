.container {
  width: 20%;
  height: 30vh;
  animation: flowin 2s, fadein 2s;
  /* animation-delay: 2s; */
}

.line {
  border-left: 2px solid #E8EFF1;
  height: 600px;
  margin-left: 22px;
}

/* Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .container {
      height: 100%;
    }

    .line {
    }
  }
}

@keyframes flowin {
  0% {
    transform: translate(0px, 400px);
    /* opacity: 0; */
  }

  100% {
    transform: translate(0px, 0px);
    /* opacity: 1; */
  }
}

@keyframes fadein {
  0% {
    /* transform: translate(0px, 50px); */
    opacity: 0;
  }

  100% {
    /* transform: translate(0px, 0px); */
    opacity: 1;
  }
}

/* <1200px screen width */
@media (max-width: 1200px) {
  .line {
    margin-left: 16px;
  }
}

/* <1050px screen width */
@media (max-width: 1050px) {
  .container {
    width: 30%;
  }
}

/* <900px screen width */
@media (max-width: 900px) {
  .line {
    margin-left: 11px;
  }
}


/* <850px screen width */
@media (max-width: 850px) {
  .container {
    width: 40%;
    visibility: hidden;
  }
}

/* <550px screen width */
@media (max-width: 850px) {
  .container {
    width: 30%;
  }
}
