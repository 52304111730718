a {
  text-decoration: none;
}

.icon_container {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.icon_container img {
  width: 44px;
  padding: 12px 0;
}

.icon_name {
  position: absolute;
  left: 58px;
  margin-top: 20px;
  color: white;
}

.icon_name:visited {
  color: white;
}

.hidden {
  display: none;
}

/* Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .icon_container img {
      height: 100%;
    }
  }
}

/* <1200px screen width */
@media (max-width: 1200px) {
  .icon_name {
    display: none;
  }

  .icon_container img {
    width: 32px;
    padding: 10px 0;
  }
}

/* <900px screen width */
@media (max-width: 900px) {
  .icon_container img {
    width: 20px;
    padding: 5px 0;
  }
  .icon_container img:hover {
    width: 22px;
    margin-left: -1px;
  }
}