.container {
  display: flex;
  flex-direction: row;
  margin: 20% auto 5% auto;
  max-width: 50%;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.527);
  border: 1px solid white;
  border-radius: 10px;
  animation: fadein 2s;
  animation-delay: 2s;
  animation-fill-mode: both;
}

.container a {
  width: auto;
}

.github_only {
  width: 30%;
}

.github_link {
  padding: 10%;
  width: 50%;
  height: auto;
}

.openlink {
  padding: 10%;
  width: 50%;
}

.container img:hover {
  transform: scale(1.1);
  cursor: pointer;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  on {
    opacity: 1;
  }
}

@media (max-width: 850px) {
  .container {
    display: flex;
    margin: 2% auto;
    width: 10%;
  }

  .container a {
    display: flex;
    margin: auto;
  }

  .container img {
    margin: auto;
  }
}

/* Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    @media (max-height: 800px) {
      .container {
        margin: 0 auto;
        padding: 0;
      }

      .container a {
        height: 5vh;
      }
    }

    /* For thinner screens */
    @media (max-height: 700px) and (max-width: 800px) {
      .container {
        margin: 0 auto;
        padding: 0;
      }

      .container a {
        margin: 0;
      }

      .github_link {
        padding: 0;
      }
    }
  }
}
