.full_container {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 10vh;
  text-align: center;
  background-color: #053742;
}

.full_container p {
  color: white;
  margin: auto;
}

@media (max-width: 700px) {
  .full_container p {
    font-size: smaller;
  }
}
