.navigation_container {
  display: flex;
  /* flex-direction: row; */
  /* width: 70%; */
  /* margin-left: 8%; */
}

/* <700px screen width ——————————————————————————————————————————————————————————*/
/* Navigation goes vertical */
@media (max-width: 700px) {
  .navigation_container {
    flex-direction: column;
    width: 80%;
    margin-bottom: 10%;
  }
}

@media (max-width: 700px) and (max-height: 500px) {
  .navigation_container {
    width: 80%;
    margin-bottom: 10%;
  }
}
