.project_container {
  display: flex;
  flex-direction: row-reverse;
  margin: 5% 0;
  padding: 20px;
  height: 80%;
  width: 100%;
  background-color: white;
  border: 4px solid #053742;
  border-radius: 10px;
  justify-content: center;
}

.fadein_image {
  width: 100%;
  height: 100%;
}

.project_name {
  font-size: 200%;
  font-weight: 500;
  text-decoration: underline;
  width: 100%;
  margin: 0;
  color: #053742;
  border-radius: 20px;
}

.project_details {
  width: 20%;
  margin: 5% 0 0 5%;
  text-align: center;
}

.project_detail {
  width: 100%;
  height: 100%;
}

.detail_header {
  font-size: 150%;
  font-weight: 300;
  color: #a3dafa;
  margin: 10% 0 0 0;
}

.detail {
  font-weight: 300;
  color: #053742;
  margin: 0;
}

.members {
  color: white;
}

.languages {
  color: white;
}

.libraries {
  color: white;
}

@media (max-width: 850px) {
  .project_container {
    flex-direction: column;
  }

  .project_img_container {
    width: 100%;
    height: 100%;
  }

  .project_name {
    font-size: 100%;
    text-align: center;
    margin: 2% auto;
    text-decoration: none;
  }

  .project_details {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 90%;
    height: 20%;
    margin: 2% 5% 0 5%;
  }

  .project_detail {
    margin: 0 0;
  }

  .detail_header {
    font-size: 100%;
  }

  .detail {
    font-size: 80%;
  }
}

/* For thinner screens */
@media (max-height: 1000px) and (max-width: 800px) {
  .project_container {
    /* padding: 0; */
  }
}

/* Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    /* .project_container {
      height: 60%;
    } */

    @media (max-height: 1000px) and (max-width: 800px) {
      .project_container {
        height: 80%;
      }
    }
  }
}
